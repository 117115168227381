import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Layout from "../components/Layout/layout";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";
import BlankButton from "../components/ui/BlankButton";
import { Link } from "gatsby";
import SEO from "../components/seo";

const Products = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicProductPage {
        nodes {
          data {
            cards {
              heading {
                text
              }
              description
              link
              background_sm {
                url
              }
              background_md {
                url
              }
              background_lg {
                url
              }
            }
          }
        }
      }
    }
  `);

  console.log(data.allPrismicProductPage.nodes[0].data.cards);

  return (
    <>
    <SEO 
    title = "Products - Kabira Mobility"/>
      <Layout>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 my-2 md:m-4">
          {data.allPrismicProductPage.nodes[0].data.cards.map((item) => {
            return (
              <>
                <div className="md:rounded-lg bg-gray-100 shadow hover:shadow-md py-12 h-[560px] lg:h-[640px] bg-no-repeat bg-cover bg-bottom md:hidden"
                style = {{backgroundImage: `url(${item.background_sm.url})`}}>
                  <div className=" flex flex-col space-y-2 items-center z-20">
                    <h2 className="text-3xl md:text-4xl text-gray-700 font-medium lg:font-semibold text-center">
                      {item.heading.text}
                    </h2>
                    <h5 className="text-lg font-light text-gray-500 text-center">
                      {item.description}
                    </h5>
                    <div className="pt-4">
                      <Link to={item.link}>
                        <BlankButton text="Learn More" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="md:rounded-lg bg-gray-100 shadow hover:shadow-md py-12 h-[560px] lg:h-[640px] bg-no-repeat bg-cover bg-bottom hidden md:block lg:hidden"
                style = {{backgroundImage: `url(${item.background_md.url})`}}>
                  <div className=" flex flex-col space-y-2 items-center z-20">
                    <h2 className="text-3xl md:text-4xl text-gray-700 font-medium lg:font-semibold text-center">
                      {item.heading.text}
                    </h2>
                    <h5 className="text-lg font-light text-gray-500 text-center">
                      {item.description}
                    </h5>
                    <div className="pt-4">
                      <Link to={item.link}>
                        <BlankButton text="Learn More" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="md:rounded-lg bg-gray-100 shadow hover:shadow-md py-12 h-[560px] lg:h-[640px] bg-no-repeat bg-cover bg-bottom hidden lg:block"
                style = {{backgroundImage: `url(${item.background_lg.url})`}}>
                  <div className=" flex flex-col space-y-2 items-center z-20">
                    <h2 className="text-3xl md:text-4xl text-gray-700 font-medium lg:font-semibold text-center">
                      {item.heading.text}
                    </h2>
                    <h5 className="text-lg font-light text-gray-500 text-center">
                      {item.description}
                    </h5>
                    <div className="pt-4">
                      <Link to={item.link}>
                        <BlankButton text="Learn More" />
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </Layout>
    </>
  );
};

export default Products;
